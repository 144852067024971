export const endPointUrls = Object.freeze({
	// authentication
	LOGIN: '/login',
	REGISTER: '/register',
	LOGOUT: '/logout',
	GET_EMAIL_VERIFY_TOKEN: '/get_email_verify_token',
	VERIFY_EMAIL_TOKEN: '/verify_email_token',
	FORGOT_PASSWORD: '/forgot_password',
	RESET_FORGOT_PASSWORD: '/reset_forgot_password',
	GENERATE2FA_SECRET: '/generate2fa_secret',
	GENERATE2FA_ENABLE: '/generate2fa_enable',
	ADD2FA_ON_TOKEN: '/add2fa_on_token',
	GENERATE2FA_DISABLE: '/generate2fa_disable',

	// user profile
	PROFILE_MANAGEMENT: '/profile_management',
	PROFILE_UPDATE_AVATAR: '/profile_management/update_avatar',
	PROFILE_UPDATE_PERSONAL: '/profile_management/update_personal_details',
	PROFILE_UPDATE_CONTACT: '/profile_management/update_contact_details',
	PROFILE_SET_SPONSOR_ID: '/profile_management/set_sponsor_id',
	PROFILE_CHANGE_TRANSACTION_PASSWORD: '/profile_management/change_transaction_password',
	PROFILE_CHANGE_USER_PASSWORD: '/profile_management/change_password',
	PROFILE_ROLES: '/profile_management/roles',

	// user package
	PACKAGE_CATEGORIES: '/packages/categories',
	PACKAGES_LIST: '/packages',
	PRODUCTS_LIST: '/packages/products',

	GET_BANNERS: '/get_banner',
	SET_BANNERS: '/admin/update_banner',

	// user transfer
	WALLET_DEPOSIT_TRANSFER_PREVIEW: '/wallets/deposit/transfer-preview',
	WALLET_DEPOSIT_TRANSFER: '/wallets/deposit/transfer-funds',
	WALLET_DEPOSIT_TRANSFERS_LIST: (query?: string) =>
		`/wallets/deposit/transfers${query ? `?${query}` : ''}`,

	// user eaning wallet
	EARNING_WALLET: '/wallets/earning',
	EARNING_WALLET_TRANSACTIONS: (query?: string) =>
		`/wallets/earning/transactions${query ? `?${query}` : ''}`,
	EARNING_WALLET_WITHDRAW: '/wallets/earning/withdraw-funds',

	// user wallets
	WALLETS: '/wallets',
	WALLETS_DEPOSIT: '/wallets/deposit/deposit-funds',
	WALLETS_WITHDRAW: '/wallets/withdraw-requests',
	WALLETS_TRANSACTION_TYPES: '/wallets/wallet_transaction_types',
	WALLETS_DEPOSIT_TRANSACTION: (query?: string) =>
		`/wallets/deposit/transactions${query ? `?${query}` : ''}`,

	// internal transfer
	INTERNAL_TRANSFER: '/wallets/withdraw-requests',
	INTERNAL_TRANSFER_PREVIEW: '/wallets/withdraw-requests/preview',
	INTERNAL_TRANSFER_LIST: (query?: string) =>
		`/wallets/withdraw-requests${query ? `?${query}` : ''}`,

	// user payment
	PAYMENT_INVOICES_ALL: (query?: string) =>
		`/payments/invoices/all${query ? `?${query}` : ''}`,
	PAYMENT_INVOICES: (query?: string) => `/payments/invoices${query ? `?${query}` : ''}`,
	UPDATE_INVOICE: '/payments/invoices/update-invoice',

	// user order
	ORDERS: (query?: string) => `/orders${query ? `?${query}` : ''}`,
	ORDERS_ACTIVE: (query?: string) => `/orders?is_canceled=0${query ? `&${query}` : ''}`,
	ORDER_SUBMIT_PREVIEW: '/orders/preview_store',
	ORDER_SUBMIT: '/orders/store',
	ORDER_CANCEL: '/orders/cancel_order',
	ORDER_TOPUP: '/orders/topUp',
	ORDER_COUNTS: '/orders/counts',

	// admin
	ADMIN_USERS: (query?: string) => `/admin/users${query ? `?${query}` : ''}`,
	ADMIN_ORDERS: (query?: string) => `/orders/admin/orders${query ? `?${query}` : ''}`,
	ADMIN_UPDATE_ORDER: '/orders/admin/orders/update_order',
	PRINT_ORDER: (id?: string) => '/orders/download_factor/' + id,
	ADMIN_USER: (userId?: string | number) =>
		`/admin/users/user${userId ? `?user_id=${userId}` : ''}`,
	ADMIN_USER_ACTIVITY: (userId?: string | number, query?: string) =>
		`/admin/users/activities/user${
			userId ? `?user_id=${userId}${query ? `&${query}` : ''}` : ''
		}`,
	ADMIN_USER_EMAIL_VERIFICATION_HISTORY: (userId?: string | number, query?: string) =>
		`/admin/users/user_email_verification_history${
			userId ? `?user_id=${userId}${query ? `?${query}` : ''}` : ''
		}`,
	ADMIN_USER_LOGIN_HISTORY: (userId?: string | number, query?: string) =>
		`/admin/users/user_login_history${
			userId ? `?user_id=${userId}${query ? `?${query}` : ''}` : ''
		}`,
	ADMIN_USER_BLOCK_HISTORY: (userId?: string | number, query?: string) =>
		`/admin/users/user_block_history${
			userId ? `?user_id=${userId}${query ? `?${query}` : ''}` : ''
		}`,
	ADMIN_USER_PASSWORD_HISTORY: (userId?: string | number, query?: string) =>
		`/admin/users/user_password_history${
			userId ? `?user_id=${userId}${query ? `?${query}` : ''}` : ''
		}`,
	ADMIN_USER_ACTIVITY_LIST: (query?: string) =>
		`/admin/users/activities/list${query ? `?${query}` : ''}`,
	ADMIN_ROLES: '/admin/role/get_roles',
	ADMIN_SUM_INVEST: '/mlm/admin/dashboard/invest_info',
	ADMIN_SUM_COMMISSIONS: '/mlm/dashboard/sum_of_commissions',
	ADMIN_SUM_INCOMES: '/orders/admin/orders/dashboard/sum_of_incomes',
	ADMIN_SUM_ORDERS: '/orders/sum_of_orders',
	ADMIN_SUM_USERS: '/orders/admin/orders/dashboard/sum_of_users',
	ADMIN_WITHDRAW_COUNTS: '/wallets/admin/withdraw-requests/counts',
	ADMIN_BEST_LEAST_SELLER: '/packages/best_least_seller',
	ADMIN_USER_CREATE: '/admin/users/create_user',
	ADMIN_CHANGE_WALLET_BALANCE: '/wallets/admin/users/change_wallet_balance',
	ADMIN_USER_BLOCK_UNBLOCK: '/admin/users/block_or_unblock_user',
	ADMIN_USER_TOGGLE_COMMISSION: '/admin/users/toggle_commission',
	ADMIN_USER_ACTIVE_DEACTIVE: '/admin/users/activate_or_deactivate_user',
	ADMIN_USER_FREEZE_UNFREEZE: '/admin/users/freeze_or_unfreeze_user',
	ADMIN_USER_AVATAR: '/admin/users/update_avatar',
	ADMIN_USER_RESET_PASSWORD: '/admin/users/reset_password',
	ADMIN_USER_HIJACK: '/admin/users/hijack',
	ADMIN_USER_VERIFY_EMAIL: '/admin/users/verify_email_user',
	ADMIN_SETTINGS: '/admin/settings',
	ADMIN_WALLET_SETTINGS: '/wallets/admin/settings',
	ADMIN_IMAGE_UPLOAD: '/packages/admin/images/upload',
	ADMIN_WALLET_SETTINGS_UPDATE: '/wallets/admin/settings/update',
	ADMIN_PACKAGE_CATEGORY_CREATE: '/packages/admin/categories/create',
	ADMIN_PACKAGES_LIST: '/packages/admin',
	ADMIN_PACKAGE_EDIT: '/packages/admin/edit',
	ADMIN_PRODUCTS_LIST: '/packages/products',
	ADMIN_PRODUCTS_DETAILS: (id: string) => '/packages/admin/products/' + id,
	ADMIN_PRODUCT_EDIT: (id: string) => '/packages/admin/products/' + id,
	ADMIN_USER_DISABLE2FA: '/admin/users/disable2fa',
	ADMIN_USER_WALLETS: (query?: string) =>
		`/wallets/admin/users/wallets-list${query ? `?${query}` : ''}`,
	ADMIN_MLM_GET_COMMISSIONS: (query?: string) =>
		`/mlm/admin/dashboard/get_commissions${query ? `?${query}` : ''}`,
	ADMIN_WALLETS_USERS_TRANSACTIONS: (query?: string) =>
		`/wallets/admin/users/all-transactions${query ? `?${query}` : ''}`,
	ADMIN_USER_COMMISSIONS: (query?: string) =>
		`/mlm/admin/dashboard/get_commissions${query ? `?${query}` : ''}`,
	ADMIN_DASHBOARD_ORDERS_COUNTS: '/orders/admin/orders/dashboard/counts',
	ADMIN_DASHBOARD_ORDERS_SUMS: '/orders/admin/orders/dashboard/sums',
	ADMIN_INVOICES: (query?: string) =>
		`/payments/admin/invoices${query ? `?${query}` : ''}`,

	// general
	COUNTRIES: '/countries',
	CITIES: '/states',
	PRODUCTS: (id = '') => {
		console.log({ id });

		return '/packages/products' + (id?.length ? `/${id}` : '');
	},

	// mlm
	MLM_INFO: '/mlm/trees/get_mlm_info',
	UPDATE_BINARY_POSITION: '/edit_binary_position',
	MLM_DASHBOARD_SALES_COUNTS: '/mlm/dashboard/sales_distribution_chart',
	MLM_GET_COMMISSIONS: (query?: string) =>
		`/mlm/get_commissions${query ? `?${query}` : ''}`,

	// binary tree
	GET_BINARY_TREE: (query?: string) =>
		`/mlm/trees/binary_multi_level${query ? `?${query}` : ''}`,
	GET_REFERRAL_TREE: (query?: string) =>
		`/mlm/trees/referral_multi_level${query ? `?${query}` : ''}`,

	// commissions
	GET_USER_COMMISSIONS: (query?: string) =>
		`/mlm/get_commissions${query ? `?${query}` : ''}`,
	ALL_COMMISSIONS_COUNTS: '/wallets/admin/users/commissions-sum',

	// notifications
	NOTIFICATIONS_LIST: (query?: string) => `/notifications${query ? `?${query}` : ''}`,
	NOTIFICATIONS_SEEN: '/set_notifications_seen',
	NOTIFICATIONS_UNREAD: '/notifications_unread',
	ADMIN_NOTIFICATIONS_SEND: '/admin/users/send_global_notification',
	ADMIN_NOTIFICATIONS_PRIVATE_SEND: '/admin/users/send_private_notification',

	ADMIN_DELETE_PACKAGE: '/packages/admin/delete',
	ADMIN_CREATE_PACKAGE: '/packages/admin/create',
	ADMIN_PACKAGE_COMMISSIONS: (id: string | number | undefined) =>
		`/packages/admin/show/${id}`,
	UPDATE_ADMIN_PACKAGE_COMMISSIONS: '/packages/admin/indirect_commissions/create_or_edit',

	ADMIN_DELETE_PRODUCT: (id: string) => '/packages/admin/products/' + id,
	ADMIN_CREATE_PRODUCT: '/packages/admin/products',
	ADMIN_PRODUCT_COMMISSIONS: (id: string | number | undefined) =>
		`/packages/admin/products/${id}`,
	UPDATE_ADMIN_PRODUCT_COMMISSIONS:
		'/packages/admin/products/indirect_commissions/create_or_edit',

	ADMIN_WITHDRAWS: (query?: string) =>
		`/wallets/admin/withdraw-requests${query ? `?${query}` : ''}`,
	WITHDRAW_LIST: (query?: string) =>
		`/wallets/withdraw-requests${query ? `?${query}` : ''}`,
	WITHDRAW_ACTION: '/wallets/admin/withdraw-requests',
	TOTAL_WITHDRAWS_COUNT: '/wallets/withdraw-requests/single_user_counts',
	SUM_COMMISSIONS: '/mlm/dashboard/single_user_sum_of_commissions',
	SUM_ORDERS: '/orders/single_user_sum_of_orders',
	INVEST_INFO: '/mlm/dashboard/single_user_invest_info',
	ORDER_INCOME_OUTCOME: '/orders/single_user_sum_of_incomes',

	ADMIN_GROUP_LIST: '/packages/admin/groups',
	ADMIN_GROUP_CREATE: '/packages/admin/groups',
	ADMIN_GROUP_DELETE: (id: string) => '/packages/admin/groups/' + id,
	ADMIN_GROUP_UPDATE: (id: string) => '/packages/admin/groups/' + id,

	USER_ADDRESS_CREATION: '/profile_management/addresses',
	USER_ADDRESS_DELETE: '/profile_management/addresses/remove',
});
