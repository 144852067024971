import { Button } from 'antd';
import Google2faAlert from 'components/HeaderAlert/components/Google2faAlert';
import { useOrderSubmitQuery } from 'components/OrderSubmitPackage/hooks/react-query/useOrderSubmitQuery';
import { getCurrentUserInfoQuery } from 'core/hooks/react-query/getCurrentUserInfoQuery';
import { useProductDetails, useProductsList } from 'core/hooks/react-query/products';
import useModal from 'core/hooks/useModal';
import { RootState } from 'core/store';
import { addBasketItem, removeBasketItem } from 'core/store/slice/basket/reducer';
import { formatNumber } from 'core/utils/common';
import useTranslate from 'core/utils/useTranslate';
import { FC, useEffect, useMemo, useState } from 'react';
import { TbMinus, TbPlus } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import DgButton from 'styles/UI/Button/DgButton';
import DgCard from 'styles/UI/Card/DgCard';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgModal from 'styles/UI/Modal/DgModal';
import DgPopConfirm from 'styles/UI/PopConfirm/DgPopConfirm';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import DgTag from 'styles/UI/Tag/DgTag';

const ProductsPage: FC = () => {
	const { t } = useTranslate();
	const { data: products } = useProductsList();

	// console.log({ products });

	return (
		<>
			{!!products?.data?.length && (
				<div
					className='grid sm:grid-cols-2 md:grid-cols-3 md:gap-8 gap-6 grid-cols-1
			'
				>
					{products.data.map((item: any, index: any) => {
						return <ProductItem key={item.id || index} data={item} />;
					})}
				</div>
			)}
		</>
	);
};

const ProductItem = ({ data: item }: { data: any }) => {
	const { t } = useTranslate();
	const dispatch = useDispatch();

	const { data, refetch } = useProductDetails(item?.id);

	const basketStore = useSelector((store: RootState) => store.basketStore);

	const isOnBasket = !!basketStore.items
		.find((filtered) => filtered.id === item.id)
		?.id?.toString?.()?.length;

	const itemQuantityOnBasket = isOnBasket
		? basketStore.items.find((filtered) => filtered.id === item.id)?.quantity || 0
		: 0;

	const hasDiscount = item.discount > 0;
	const image = !!item?.images?.length && item.images[0];

	const purchaseModal = useModal();

	// console.log({ item, addresses, otp, basketStore, isOnBasket }, purchaseModal.modal);

	useEffect(() => {
		if (purchaseModal.modal) {
			// refetch();
			// refetchProfile();
		} else {
			// setSelectedAddress(null);
		}
	}, [purchaseModal.modal]);

	return (
		<>
			<DgModal
				width={480}
				title={'purchase'}
				onCancel={purchaseModal.hideModal}
				open={purchaseModal.modal}
			>
				<div className='flex flex-col gap-2'>
					{image?.url && <img src={image.url} />}

					<div className='flex items-start justify-between gap-4 flex-wrap'>
						<div className='flex flex-col gap-1'>
							<h3 className='text-2xl font-bold'>{item.title}</h3>
							<p className='max-w-[16rem]'>{item.description}</p>
						</div>
						<div className='flex flex-col items-end gap-1'>
							<DgPrice price={0}>
								<span className={hasDiscount ? 'line-through' : ''}>
									{formatNumber(item.price)}
								</span>
							</DgPrice>

							{hasDiscount && (
								<>
									<DgPrice price={0}>
										<span className=''>
											{formatNumber(item.price - (item.price * item.discount) / 100)}
										</span>
									</DgPrice>
									<DgTag color='var(--dg-red-color-2)'>
										{item.discount}% {t('off')}
									</DgTag>
								</>
							)}
						</div>
					</div>

					<div className='flex flex-col'>
						{!isOnBasket ? (
							<DgButton
								// icon={<TbMinus />}
								title='purchase'
								onClick={() => dispatch(addBasketItem(item.id))}
							/>
						) : (
							<div className='flex flex-col gap-2'>
								<div className='flex items-center justify-center gap-2'>
									<Button
										className='flex items-center justify-center'
										icon={<TbPlus />}
										onClick={() => dispatch(addBasketItem(item.id))}
										disabled={itemQuantityOnBasket >= item.qty}
									/>

									{itemQuantityOnBasket}

									<Button
										className='flex items-center justify-center'
										icon={<TbMinus />}
										onClick={() => dispatch(removeBasketItem(item.id))}
										disabled={!isOnBasket}
									/>
								</div>

								<DgButton title='continue' onClick={() => purchaseModal.hideModal()} />
							</div>
						)}
					</div>
				</div>
			</DgModal>

			<DgCard className='flex flex-col gap-2 justify-between' key={item.id}>
				{image?.url && <img src={image.url} />}
				<div className='flex items-start justify-between gap-4 flex-wrap'>
					<h3 className='font-bold text-xl'>{item.title}</h3>

					<div className='flex flex-col items-end gap-1'>
						<DgPrice price={0}>
							<span className={hasDiscount ? 'line-through' : ''}>
								{formatNumber(item.price)}
							</span>
						</DgPrice>
						{hasDiscount && (
							<>
								<DgPrice price={0}>
									<span className=''>
										{formatNumber(item.price - (item.price * item.discount) / 100)}
									</span>
								</DgPrice>
								<DgTag color='var(--dg-red-color-2)'>
									{item.discount}% {t('off')}
								</DgTag>
							</>
						)}
					</div>
				</div>

				{/* <Link className='w-full' to={routes.BUY_PRODUCT(item.id)}> */}

				{!isOnBasket ? (
					<DgButton
						title='purchase'
						onClick={() => {
							purchaseModal.showModal();
							dispatch(addBasketItem(item.id));
						}}
					/>
				) : (
					<div className='flex items-center justify-stretch gap-2'>
						<div className='flex items-center justify-center gap-2'>
							<Button
								className='flex items-center justify-center'
								icon={<TbPlus />}
								onClick={() => dispatch(addBasketItem(item.id))}
								disabled={itemQuantityOnBasket >= item.qty}
							/>

							{itemQuantityOnBasket}

							<Button
								className='flex items-center justify-center'
								icon={<TbMinus />}
								onClick={() => dispatch(removeBasketItem(item.id))}
								disabled={!isOnBasket}
							/>
						</div>

						<DgButton
							className='w-full'
							title='details'
							onClick={() => purchaseModal.showModal()}
						/>
					</div>
				)}
				{/* </Link> */}
			</DgCard>
		</>
	);
};

export default ProductsPage;
