import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
// import { IGroupList } from '../../../../../../core/types/models/groupType';

export const useAdminGroupQuery = () => {
	return useQueryHook(
		[endPointUrls.ADMIN_GROUP_LIST],
		(data: any) =>
			api.get(endPointUrls.ADMIN_GROUP_LIST, {
				...data,
				// params: {
				// 	...(data?.params || {}),
				// 	// group_exactly: true,
				// },
			}),
		{
			select: (res: any) => res?.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<any, Error>;
};
